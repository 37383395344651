import { useQuery } from '@apollo/client';
import { Avatar, Button, ButtonGroup, Chip, Grid,  IconButton,  Tooltip,  Typography } from '@mui/material';
import clsx from 'clsx';
import { differenceInDays, format } from 'date-fns';
import { delay, orderBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import Icon from '../../components/Icon';
import Loader from '../../components/Loader';
import { nextMovieNight } from '../../helpers/dateMethods';
import useTitlebar from '../../hooks/useTitlebar';
import { GUILD_CONFIG } from '../../queries/guild/config';
import { GUILD_STORE } from '../../queries/guild/store';
import { GUILD_STORE_ITEM } from '../../queries/guild/storeItem';
import styles from './styles.module.scss';

interface PositionState {
  top: number;
  left: number;
  width: number;
  height: number;
}

const List = () => {
  const [nextStream, setNextStream] = useState<Date | null>(null);
  const [active, setActive] = useState<boolean>(false);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [activePosition, setActivePosition] = useState<PositionState | null>(null);

  const params = useParams();
  const { titlebar, setTitlebar } = useTitlebar();

  const guildId = params.guildId;

  const { data: config, error: configError, loading: configLoading } = useQuery(GUILD_CONFIG, {
    variables: {
      guildId,
    },
  });
  const guildConfig = config?.config;

  const { data: store, error: storeError, loading: storeLoading } = useQuery(GUILD_STORE, {
    variables: {
      guildId,
    },
  });
  const guildStore = orderBy(store?.store, ['addedOn'], ['desc']).sort((x, y) =>
    x?.pickedOn && nextStream && differenceInDays(nextStream, x.pickedOn) <= 7 ? -1 : 1,
  );

  const openModal = (event: any) => {
    const card = event.target.closest('.card');

    setActiveId(card.dataset.id);

    delay(() => {
      setActivePosition(card.getBoundingClientRect());
      setActive(true);
    }, 300);
  };

  const closeModal = async () => {
    setActive(false);
    delay(() => {
      setActiveId(null);
      setActivePosition(null);
    }, 1000);
  };

  useEffect(() => {
    setTitlebar({
      title: guildConfig ? guildConfig?.guildName : '',
    })
    setNextStream(
      nextMovieNight(guildConfig?.when && Object.entries(guildConfig.when))
    );
  }, [guildConfig]);

  const stringAvatar = (name: string) => {
    return {
      children: `${name.split(' ').map(part => part[0]).join('') || ''}`
    }
  }

  return configLoading || storeLoading
    ? <Loader />
    : guildConfig && guildStore
      ? <div className={ styles.root }>
        <Helmet>
          <title>{ guildConfig.guildName } - List</title>
          <meta name="description" content={`Movie list for ${ guildConfig.guildName } containing ${ guildStore?.length } movies!`} />
        </Helmet>

        <div
          className={ clsx( styles.cardContainer, active && styles.active ) }
        >
          { activePosition && <GridItem
            item={guildStore.find(({_id}: any) => _id === activeId)}
            popup
            active={true}
            style={{
              position: 'fixed',
              zIndex: 99999,
              top: activePosition?.top,
              left: activePosition?.left,
              width: activePosition?.width,
              height: activePosition?.height,
            }}
            onClick={closeModal}
          >
            <ItemDetails
              item={guildStore.find(({_id}: any) => _id === activeId)}
              onClose={closeModal}
            />
          </GridItem> }
        </div>

        <div className={ styles.heading }>
          <Typography color="primary" variant="h3">
            <Avatar
              { ...stringAvatar(guildConfig.guildName) }
              className={ styles.avatar }
              src={ guildConfig.guildIcon }
              sx={{ width: 56, height: 56, bgcolor: 'var(--primary-color)' }}
            />
            { guildConfig.guildName }
          </Typography>
          {/* <div>
            <Tooltip arrow title="Go to channel">
              <IconButton color="inherit" href={`/castle/${guildId}`}>
                <Icon name="dungeon" solid size={32} />
              </IconButton>
            </Tooltip>
          </div> */}
          <div>
            { nextStream && <Typography variant="body2">
              Next Stream: { format(nextStream, 'EEE do MMM') }
            </Typography> }
            <Typography variant="body2">
              { guildStore?.length } movie{ guildStore?.length !== 1 ? 's' : '' } ( <Icon name="circle" solid size={12} color="var(--danger-color)" /> { guildStore.filter(i => i.locked === 2).length } watched • <Icon name="circle" solid size={12} color="var(--warning-color)" /> { guildStore.filter(i => i.locked === 1).length } locked )
            </Typography>
          </div>
        </div>
        <br />
        <br />
        <br />
        <Grid container spacing={4} justifyContent="center">
          { guildStore.map((item: any, index: number) =>
            <GridItem item={item} active={item._id === activeId} nextStream={nextStream} key={index} onClick={ openModal } />
          ) }
        </Grid>
      </div>
      : <div className={ styles.root }>
        <Helmet>
          <title>Guild not set up</title>
          <meta name="description" content="This system is invite only" />
        </Helmet>
        <Typography color="primary" variant="h3">
          Guild not set up
        </Typography>
        <Typography variant="h5">
          This system is invite only
        </Typography>
      </div>
}

const GridItem = ({ item, active, index, style, popup, onClick, nextStream, children }: any) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    delay(() =>
      setOpen(true),
      100,
    );
  }, [popup]);

  const closePopup = () => {
    setOpen(false);
    onClick();
  };

  if (!item) return null;

  const isNextStream = (item.pickedOn && nextStream && differenceInDays(nextStream, item.pickedOn) <= 7);

  return <Grid
    item
    xs={6}
    sm={6}
    md={4}
    lg={3}
    xl={2}
    key={ item._id }
  >
    <div
      className={ clsx(styles.cardOuter, {
        [styles.nextUp]: item?.pickedOn && nextStream && differenceInDays(nextStream, item.pickedOn) <= 7,
        [styles.cardOuterActive]: popup && open,
      }) }
      style={{
        ...style,
      }}
    >
      <div
        className={ clsx('card', styles.card, {
          [styles.status_0]: item.locked === 0,
          [styles.status_1]: item.locked === 1,
          [styles.status_2]: item.locked === 2,
          [styles.status_3]: item?.pickedOn && nextStream && differenceInDays(nextStream, item.pickedOn) <= 7,
          [styles.hover]: active,
          [styles.cardActive]: popup && open,
        }) }
        data-id={ item._id }
        style={{
          "--poster": item.poster
            ? `url(https://www.themoviedb.org/t/p/w400${item.poster})`
            : 'url(https://lands-tube.it.landsd.gov.hk/AVideo/view/img/notfound_portrait.jpg)',
          "--backdrop": item.backdrop
            ? `url(https://www.themoviedb.org/t/p/w1000_and_h563_face${item.backdrop})`
            : 'url(https://lands-tube.it.landsd.gov.hk/AVideo/view/img/notfound_portrait.jpg)',
          width: (popup && open) ? '100%' : style?.width,
          height: (popup && open) ? 646 : style?.height,
        } as any}
        key={ index }
      >
        <div className={ clsx(styles.content, {
          [styles.contentActive]: popup && open,
        }) }>
          { !active && <>
            <h6>
              <b>{ item.title }</b>
            </h6>
            <Typography variant="body1">By: { item.addedBy.name }</Typography>
            <Typography variant="body2">On: { format(item.addedOn, 'do MMM yyyy') }</Typography>
            { item.locked === 1 &&
              <Chip variant="filled" color="warning" icon={<Icon regular name="lock" />} label="Locked" />
            }
            { (item.locked === 2 && !item.pickedOn) &&
              <Chip variant="filled" color="error" icon={<Icon regular name="eyes" />} label={`Watched`} />
            }
            { (item.locked === 2 && item.pickedOn && !isNextStream) &&
              <Chip variant="filled" color="error" icon={<Icon regular name="eyes" />} label={`Watched on ${ format(item.pickedOn, 'do MMM yyyy') }`} />
            }
            <ButtonGroup>
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<Icon regular name={ popup ? "times" : "chevron-down"} />}
                onClick={ popup ? closePopup : onClick }
              >
                { popup ? "Close" : "Info" }
              </Button>
            </ButtonGroup>
          </> }
          { children }
        </div>
      </div>
      { (nextStream && isNextStream) &&
        <Chip
          className={ styles.nextUpChip }
          variant="filled"
          color="primary"
          icon={<Icon regular name="calendar" />}
          label={
            <b>
              { format(nextStream, "do MMM '@' HH:mm 'UTC'") }
              <br />
              <Countdown
                date={nextStream}
              >
                <b>Watching Now!</b>
              </Countdown>
            </b>
          }
        />
      }
    </div>
  </Grid>;
}

const ItemDetails = ({ item, onClose }: any) => {

  const { data, loading, error } = useQuery(GUILD_STORE_ITEM, {
    variables: {
      itemId: item._id,
    }
  });
  const entry = data?.storeItem;
  const movie = entry?.movieData;

  return (movie && !loading) ? <>
    <div className={ styles.cover }>
      <IconButton
        color="inherit"
        size="large"
        className={ styles.closeButton }
        onClick={ onClose }
      >
          <Icon name="close" color="inherit" size={30} />
      </IconButton>
      <Typography variant='h3'>
        { item.title }
      </Typography>
      <Typography variant='body1'>
        { movie.tagline }
      </Typography>
    </div>
    { movie.homepage &&
      <Button href={ movie.homepage } target="_blank">Visit Website</Button>
    }
    { error?.message }
    <>
      <div>
        { movie.production_companies.map( (company: any) => `${company.name}` ).join(' | ') }
      </div>
      <div>
        { movie.release_date.slice(0, 4) } | { movie.runtime } minutes
      </div>
      <div className={ styles.countries }>
        { Object.entries(movie.providers.results)
          .map( ([countryCode, countryData]: [string, any]) => <>
            <div>
              <img src={`https://flagicons.lipis.dev/flags/4x3/${countryCode.toLowerCase()}.svg`} />
              
              <div className={ styles.providerLists }>
                { countryData?.buy?.length && <div>
                  <div>BUY</div>
                  { countryData?.buy?.map((item: any) =>
                    <div>
                      <img src={ `https://www.themoviedb.org/t/p/original/${item.logo_path}` } />
                    </div>
                  ) }
                </div> }

                { countryData?.rent?.length && <div>
                  <div>RENT</div>
                  { countryData?.rent?.map((item: any) =>
                    <div>
                      <img src={ `https://www.themoviedb.org/t/p/original/${item.logo_path}` } />
                    </div>
                  ) }
                </div> }

                { countryData?.flatrate?.length && <div>
                  <div>STREAM</div>
                  { countryData?.flatrate?.map((item: any) =>
                    <div>
                      <img src={ `https://www.themoviedb.org/t/p/original/${item.logo_path}` } />
                    </div>
                  ) }
                </div> }
              </div>
            </div>
          </>) }
      </div>
    </>
  </> : null;
}

export default List;