import './style.scss';

import { useCallback, useEffect } from 'react';
import Helmet from 'react-helmet';

import showNotification from '../../components/extras/showNotification';
import request from '../../helpers/getResource';

const Session = () => {
  const runAuthFinalize = useCallback(async () => {
    const query = new URL(window.location.href).searchParams;
    const code = query.get('code');
    const redirect = `${window.location.protocol}//${window.location.host}/session`;

    const req = await request(process.env.REACT_APP_AUTH_URI as string, {
      method: 'POST',
      data: {
        code,
        redirect_uri: redirect,
      }
    });

    if ( req.auth?.error ) {
      showNotification('Authentication Error', req.auth.error_description, 'danger');
    }

    if ( req.token ) {
      window.opener.postMessage({ token: req.token }, window.opener.origin);
      setTimeout(() => window.close(), 100);
    }
  },  []);

  useEffect(() => {
    window.resizeTo(1, 1);
    window.moveTo(-1000, -1000);

    runAuthFinalize();
  }, [runAuthFinalize]);

  return <div className="main">
    <Helmet>
      <title>Finializing your session...</title>
    </Helmet>
    <h3>Finializing your session...</h3>
  </div>;
};

export default Session;