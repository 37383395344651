import './style.scss';

import { useQuery } from '@apollo/client';
import { useEffect } from 'react';

import { openWindow } from '../../helpers/openWindow';
import useSession from '../../hooks/useSession';
import useTitlebar from '../../hooks/useTitlebar';
import { ME } from '../../queries/me';

interface IAPISession {
  token: string;
}

const Index = () => {
  const { session, dispatch } = useSession();

  const doAuth = (data: IAPISession) => {
    const { token } = data;
    window.removeEventListener('message', () => null);
    dispatch(token);
  };
  
  const login = async () => {
    const redirect = `${window.location.protocol}//${window.location.host}/session`;
    const scope = ['identify', 'guilds'].join('%20');

    const url = `${process.env.REACT_APP_AUTH_URI as string}?redirect=${encodeURIComponent(redirect)}&scope=${scope}`;
    openWindow(
      url,
      404,
      752,
      'auth'
    );
    window.addEventListener('message', ({ data }: { data: IAPISession }) => doAuth(data));
  }

  const { setTitlebar } = useTitlebar();

  const { data, error, loading } = useQuery(ME, {
    context: {
      headers: {
        authorization: session,
      }
    }
  });
  const username = data?.me?.name;

  useEffect(() => {
    setTitlebar(null);
  }, []);

  return <div>
    { !session &&
      <button className='discord-login' onClick={login}>Login with Discord to join</button>
    }

    { session && <>
      Welcome { username }
    </> }
  </div>;
}

export default Index