import './style.scss';

import { useQuery } from '@apollo/react-hooks';
import { useLocation } from 'react-router';

import { IAction } from '../../contexts/titlebarContext';
import useSession from '../../hooks/useSession';
import useTitlebar from '../../hooks/useTitlebar';
import { ME } from '../../queries/me';
import Icon from '../Icon';
import { Link } from 'react-router-dom';
import { ListItem, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { random } from 'lodash';

const routeBlacklist = [
  '/session',
];

const Titlebar = () => {
  const { pathname } = useLocation();
  const { session, dispatch } = useSession();
  const { titlebar } = useTitlebar();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data } = useQuery(ME, {
    context: {
      headers: {
        authorization: session,
      }
    }
  });
  const username = data?.me?.name;
  const userId = data?.me?.id;

  const logout = () =>
    dispatch(null, 'Successfully logged out!');

  if ( routeBlacklist.includes(pathname) ) {
    return null;
  }

  return (
    <div className="titlebar" style={{ display: titlebar?.ui === 'none' ? 'none': 'flex' }}>
      <Icon name="mrv" custom size={25} color="inherit" />
      <div className="title">{ titlebar?.title }</div>

      <div className="buttons left">
        { titlebar?.leftActions?.map((action: IAction | null) => {
          if( !action ) return;

          const id = random().toString();

          if ( action.type === 'button' ) {
            return <button onClick={action.onClick} key={id}>
              { action.icon && <Icon {...action.icon} /> } { action.label }
            </button>
          } else if( action.type === 'menu' ) {
            return <span key={id}>
              <button
                onClick={handleClick}
                data-id={id}
              >
                { action.icon && <Icon {...action.icon} /> } { action.label }
              </button>
              <Menu
                anchorEl={anchorEl}
                open={anchorEl?.dataset?.id === id}
              >
                { action.menu.map(item => {
                  const id = random().toString();

                  return <ListItem key={id} onClick={handleClose}>
                    { item.icon && <ListItemIcon>
                      <Icon {...item.icon} />
                    </ListItemIcon> }
                    {item.label}
                  </ListItem>
                }) }
              </Menu>
            </span>
          }

          return null;
        }) }
      </div>
      
      <div className="buttons right">
        { titlebar?.rightActions?.map((action: IAction | null) => {
          if( !action ) return;

          const id = random().toString();

          if ( action.type === 'button' ) {
            return <button onClick={action.onClick} key={id}>
              { action.icon && <Icon {...action.icon} /> } { action.label }
            </button>
          } else if( action.type === 'menu' ) {
            return <span key={id}>
              <button
                onClick={handleClick}
                data-id={id}
              >
                { action.icon && <Icon {...action.icon} /> } { action.label }
              </button>
              <Menu
                anchorEl={anchorEl}
                open={anchorEl?.dataset?.id === id}
              >
                { action.menu.map(item => {
                  const id = random().toString();

                  return <ListItem key={id} onClick={handleClose}>
                    { item.icon && <ListItemIcon>
                      <Icon {...item.icon} />
                    </ListItemIcon> }
                    {item.label}
                  </ListItem>
                }) }
              </Menu>
            </span>
          }

          return null;
        }) }
        
        <Link to={`/dungeon/${ userId }`}>
          { session && <button>{username}</button> }
          </Link>
        { session &&
          <button onClick={logout}>
            <i className="fa-solid fa-sign-out" /> Logout
          </button>
        }
      </div>
    </div>
  );
};

export default Titlebar;
