import { gql } from '@apollo/client';

export const GUILD_CONFIG = gql`
  query getGuildConfig($guildId: String!) {
    config(guildId: $guildId) {
      _id
      guildName
      guildIcon
      when
    }
  }
`;
