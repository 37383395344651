import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/react-hooks';
import { purple } from '@mui/material/colors';
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/system';
import { Children, ReactNode } from 'react';

import { TitlebarContextProvider } from './contexts/titlebarContext';
import useSession from './hooks/useSession';

/**
 * NOTE: Session Provider must wrap this component in `index.tsx`, for session context to work
 */

const cache = new InMemoryCache();

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#2696ca',
    },
    secondary: {
      main: purple[500],
    },
  }
});

interface ProvidersProps {
  children?: any;
}

const Providers = ({ children }: ProvidersProps) => {
  const { session } = useSession();

  const apolloClient = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    cache,
    connectToDevTools: true,
    headers: {
      Authorization: `${session}`,
    }
  });

  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <TitlebarContextProvider>
          { Children.toArray(children) }
        </TitlebarContextProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default Providers;
