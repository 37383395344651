import PropTypes from 'prop-types';
import { ComponentProps, createContext, useState } from 'react';
import { IconProps } from '../components/Icon';

export interface IMenuItem {
  label?: string;
  onClick?: () => void;
  icon?: IconProps;
}

export interface IButton {
  type: 'button' | 'item';
  label?: string;
  onClick?: () => void;
  icon?: IconProps;
}

export interface IMenu {
  type: 'menu';
  label?: string;
  menu: IMenuItem[],
  icon?: IconProps;
}

export type IAction = IButton | IMenu;

interface ITitlebar {
  title?: string;
  leftActions?: (IAction | null)[];
  rightActions?: (IAction | null)[];
  ui?: string | "none" | "minimal" | "full" | null | undefined;
}

interface IContext {
  titlebar: ITitlebar | null;
  setTitlebar: (titlebar: ITitlebar | null) => void;
} // tells tslint to shut up

const TitlebarContext = createContext<IContext>({ titlebar: null, setTitlebar: () => null });

const defaultTitlebar: ITitlebar = {
  title: 'MRVDOG TV',
  leftActions: [],
  rightActions: [],
};

export const TitlebarContextProvider = ({ children }: ComponentProps<any>) => {
  const [titlebar, setTitlebar] = useState<ITitlebar>(defaultTitlebar);

  return (
    <TitlebarContext.Provider
      value={{
        titlebar,
        setTitlebar: (ttl) =>
          setTitlebar({
            ...defaultTitlebar,
            ...ttl || {},
            title: `${defaultTitlebar.title}${ ttl?.title ? ` | ${ttl.title}` : ''}`
          }),
      }}
    >
      {children}
    </TitlebarContext.Provider>
  );
};

TitlebarContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TitlebarContext;
