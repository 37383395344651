import { gql } from '@apollo/client';

export const GUILD_STORE_ITEM = gql`
  query getStoreItem($itemId: String!) {
    storeItem(itemId: $itemId) {
      _id
      addedBy {
        name
      }
      addedOn
      title
      locked
      pickedOn
      poster
      backdrop
      movieId
      movieData
    }
  }
`;
