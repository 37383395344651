import { gql } from '@apollo/client';

export const GUILD_STORE = gql`
  query getStoreByGuidId($guildId: String!) {
    store(guildId: $guildId) {
      _id
      addedBy {
        name
      }
      addedOn
      title
      locked
      pickedOn
      poster
      backdrop
      movieId
    }
  }
`;
