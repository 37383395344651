import {
  endOfDay,
  format,
  nextFriday,
  nextMonday,
  nextSaturday,
  nextSunday,
  nextThursday,
  nextTuesday,
  nextWednesday,
} from 'date-fns';
import { now } from 'lodash';
import { DateTime } from 'luxon';

export const days = [
  'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
];

export const nextDays = [
  format(new Date(), 'EEEE') === days[0] ? new Date() : nextSunday(now()),
  format(new Date(), 'EEEE') === days[1] ? new Date() : nextMonday(now()),
  format(new Date(), 'EEEE') === days[2] ? new Date() : nextTuesday(now()),
  format(new Date(), 'EEEE') === days[3] ? new Date() : nextWednesday(now()),
  format(new Date(), 'EEEE') === days[4] ? new Date() : nextThursday(now()),
  format(new Date(), 'EEEE') === days[5] ? new Date() : nextFriday(now()),
  format(new Date(), 'EEEE') === days[6] ? new Date() : nextSaturday(now()),
];

export const nextMovieNight = (when: any[]): Date | null => {
  if ( !when || when.length === 0 ) return null;

  let nextMovieNight = null;

  // loops, converts to date and sorts by date
    let dates = [];
    
    for ( const [day, times] of when ) {
      const start = times.start.split(':');

      dates.push(
        DateTime.fromJSDate(nextDays[day]).set({
          hour: start[0],
          minute: start[1],
          second: 0,
        }).toJSDate()
      );
    }
    
    dates = dates.sort((a, b) => a.getTime() - b.getTime());
  // end

  for ( const date of dates ) {
    if ( new Date() <= date ) {
      nextMovieNight = date;
      break;
    }
  }

  return nextMovieNight;
}