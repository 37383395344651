export const getWindowCenter = (width: number, height: number) => {
	let x = window.screenX;
	let y = window.screenY;
	const zPortWidth = window.innerWidth
		? window.innerWidth
		: document.documentElement.clientWidth
			? document.documentElement.clientWidth
			: x;
	const zPortHeight = window.innerHeight
		? window.innerHeight
		: document.documentElement.clientHeight
			? document.documentElement.clientHeight
			: y;
	x = zPortWidth / 2 - width / 2 + x;
	y = zPortHeight / 2 - height / 2 + y;

	return {x, y};
};

export const openWindow = (link: string = 'about:blank', width: number = 100, height: number = 100, name?: string) => {
	const {x, y} = getWindowCenter(width, height);
	const win = window.open(
		link,
		name || '_blank',
		`toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbar=no,resizable=no,scrollbars=yes,width=${width},height=${height},top=${y},left=${x}`,
	);
	return win;
}