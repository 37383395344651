import { IconName } from '@fortawesome/fontawesome-common-types';
import clsx from 'clsx';

export interface IconProps {
  regular?: true;
  solid?: true;
  brands?: true;
  light?: true;
  thin?: true;
  duotone?: true;
  custom?: true;
  name: IconName | "synerate" | "synerate-outline" | "trovo" | "beepbot-pro" | "mrv";
  size?: number;
  color?: string;
  className?: string;
}

const Icon = ({ regular, solid, brands, light, thin, duotone, custom, name, size, color, className }: IconProps) => (
  <i className={ clsx(
    'fa-regular',
    {
      'fa-brands': brands,
      'fa-solid': solid,
      'fa-regular': regular,
      'fa-light': light,
      'fa-thin': thin,
      'fa-duotone': duotone,
      'fak': custom,
    },
    `fa-${name}`,
    className,
  ) } style={{ height: size || 20, width: size || 20, color, }}></i>
)

export default Icon;