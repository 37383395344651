import PropTypes from 'prop-types';
import { ComponentProps, createContext, useEffect, useState } from 'react';

import showNotification from '../components/extras/showNotification';

interface IContext {
  session: string | null;
  dispatch: (token: string | null, logout?: string) => void;
} // tells tslint to shut up

const SessionContext = createContext<IContext>({ session: null, dispatch: () => null });

export const SessionContextProvider = ({ children }: ComponentProps<any>) => {
  const [session, setSession] = useState(localStorage.getItem('session'));

  useEffect(() => {
    if ( session !== null && session !== undefined ) {
      localStorage.setItem('session', session);
    } else if ( session === null ) {
      localStorage.removeItem('session');
    }
  }, [session]);

  return (
    <SessionContext.Provider
      value={{
        session,
        dispatch: (token, logout = '') => {
          if ( logout !== '' ) {
            showNotification(
              "Logged Out",
              logout,
              'success'
            );
          }
          setSession(token);
        }
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

SessionContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SessionContext;
