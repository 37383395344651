import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import useTitlebar from '../../hooks/useTitlebar';
import { GUILD_CONFIG } from '../../queries/guild/config';
import styles from './styles.module.scss';
import { Button } from '@mui/material';

const useQ = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const Player = () => {

  const query = useQ();
  const { castleId, dungeonId } = useParams();
  const type = castleId ? 'castle' : dungeonId ? 'dungeon' : null;

  const { data: config, error: configError, loading: configLoading } = useQuery(GUILD_CONFIG, {
    variables: {
      guildId: castleId,
    },
  });
  const guildConfig = config?.config;

  const { titlebar, setTitlebar } = useTitlebar();

  useEffect(() => {
    setTitlebar({
      title: guildConfig ? guildConfig?.guildName : '',
    })
  }, [config]);

  return <div className={ styles.root }>
    <div className={ styles.content }>
      <div className={ styles.video }>
        <div className={ styles.videoInner }>
          <video controls>
            <source src="https://d2uav5q06z9nv6.cloudfront.net/61194f17cc70462cef09dc82/assets/d099eae2-daac-4f13-88eb-956065d8f248.mp4" type="video/mp4" />
          </video>
        </div>
        <div className={ styles.controls }>
          <div>
            { castleId && <Button variant="contained" href={`/list/${castleId}`}>View Movie List</Button> }
          </div>
          <div>
            { castleId && <Button variant="contained">A right action</Button> }
          </div>
        </div>
      </div>
      <div className={ styles.chat }>
        <div className={ styles.chatInner }>
          chat
        </div>
        <div className={ styles.controls }>
          <div>
            { castleId && <Button variant="contained">A Chat action</Button> }
          </div>
        </div>
      </div>
    </div>
  </div>;
}

export default Player;