import { Navigate, useRoutes } from 'react-router-dom';

import Index from './pages/Index';
import List from './pages/List';
import Room from './pages/Room';
import Session from './pages/Session';

export const RoutesList = [
    {
        element: <Index />,
        exact: true,
        path: '/',
    }, {
        element: <Session />,
        exact: true,
        path: '/session',
    }, {
        element: <Room />,
        path: '/dungeon/:dungeonId',
    }, {
        element: <Room />,
        path: '/castle/:castleId',
    }, {
        element: <List />,
        exact: true,
        path: '/list/:guildId',
    }, {
        element: <Navigate to="/" />,
        path: '/*'
    },
];

export default function Routes() {
    const routes = useRoutes(RoutesList);

    return routes;
};
