interface RequestOptions extends RequestInit {
    data?: BodyInit | { [key: string]: any };
}

const request = async (path: string, options?: RequestOptions) => {
    const headers = new Headers();
    const token = localStorage.getItem('session');

    if ( options?.method ) {
        options.method = options.method.toUpperCase();
    }

    if ( options?.data ) { // replace none string body to string
        options.body = JSON.stringify(options.data);
        delete options.data;
        options.method = options.method || 'POST';
    }

    if ( token ) {
        headers.append('x-token', token);
    }

    return fetch(path.includes('https://') ? path : `${process.env.API_HOST}/${ path.replace(/^\//, '') }`, {
        ...options,
        headers: {
            ...options?.headers,
            "Content-Type": "application/json",
            ...headers,
        },
    })
        .then(res => res.json())
        .catch(error => {
            console.error('getResource:', error);

            return {
                error,
            };
        });
};

export default request;
