import 'react-notifications-component/dist/theme.css';

import clsx from 'clsx';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { ReactNotifications } from 'react-notifications-component';
import { Navigate } from 'react-router-dom';

import styles from './App.module.scss';
import Loader from './components/Loader';
import Titlebar from './components/Titlebar';
import Routes from './routes';

Modal.setAppElement('#root');

const App = () => {
  const [navTo, setNavTo] = useState<string | null>(null);

  useEffect(() => {
    if ( process.env.NODE_ENV === 'production' ) {
      const tracker: any = document.createElement('script');
        tracker.src = 'https://lead.dogg.gy/umami.js';
        tracker.async = true;
        tracker.defer = true;
        tracker['data-website-id'] = 'feedbc8d-c30c-4a89-b778-a7be71f3ac52';
      document.body.appendChild(tracker);
    } else {
      console.info('%cAnalytics disabled, not on production domain.', `color: orange;font-size: 24px;`);
    }
  }, []);

  if (navTo) {
    return <Navigate to={navTo} />;
  }

  return <>
    <ReactNotifications />
    <div className="content">
      { false && <Loader /> }
    </div>
    <div className={clsx(styles.app)}>
      <Titlebar />
      <main>
        <Routes />
      </main>
    </div>
  </>;
};

export default App;
